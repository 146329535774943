
import { defineComponent } from 'vue'
import TmColoredUl from '@/components/shared/templates/TmColoredUl.vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import AuthWhiteBlock from '@/components/pages/auth/AuthWhiteBlock.vue'
import AuthVerifyBlock from '@/components/pages/auth/AuthVerifyBlock.vue'

export default defineComponent({
  components: {
    TmColoredUl,
    AuthWhiteBlock,
    AuthVerifyBlock,
    PageAuth,
  },
})
